// 3rd Party
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'

// Helpers
import { enableBodyScroll, formatUrlSlug } from '@/lib/helper'

// Components
import Icon from '@/components/Icon/Icon'
import SearchBar from './SearchBar'
import AccountButton from '../Common/AccountButton'
import CartButton from '../Common/CartButton'
import LandscapeCTA from '../Common/LandscapeCTA'
import TopLevelLinks from './TopLevelLinks'
import TopLevelMenus from './TopLevelMenus'

import { navClick } from '@/lib/ga-events'

const DynamicWebMenuModal = dynamic(() => import('./WebMenuModal'), {
  loading: () => null,
  ssr: false,
})

const WebHeader = ({
  cart,
  cartModalViewHandler,
  customer,
  hasCart,
  hasLogin,
  hideSearch = false,
  hideSubMenu = false,
  isOpenMenuModal,
  setIsOpenMenuModal,
  menuModalHandler,
  modalViewHandler,
  navigateSystemBuilder,
  preview,
  search,
  searchPlaceholderText,
  searchRequest,
  setNavigateSystemBuilder,
  setSearch,
  signInModalHandler,
  subMenu,
  showPromobar,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [selectedTopLevel, setSelectedTopLevel] = useState(null)
  const [selectedSubLevel, setSelectedSubLevel] = useState(null)
  const [preventClickOutsideClose, setPreventClickOutsideClose] = useState(false)
  const [isValidHeight, setValidHeight] = useState('150px')
  const [modalProducts, setModalProducts] = useState([])
  const router = useRouter()

  const handleNavItemClick = (category, e, i) => {
    e.preventDefault()
    setSelectedCategory(category?.sys?.id || null)
    setSelectedTopLevel(i+1)
    setSelectedSubCategory(null)
    setSelectedSubLevel(null)
    const promobar = typeof document != 'undefined' ? document.querySelector('.promobar') : null

    if (promobar) {
      setValidHeight('200px')
    } else {
      setValidHeight('150px')
    }

    if (category?.navigationLink == '/system-builder') {
      menuModalHandler(true)
    }

    if (category?.navigationLink != '/system-builder') {
      enableBodyScroll()
    }

    category?.submenu?.items && menuModalHandler(false)

    if (category?.navigationLink == '/system-builder') {
      setNavigateSystemBuilder?.() && setNavigateSystemBuilder(!navigateSystemBuilder)
    }

    if (category?.navigationaction && category?.navigationLink) {
      navClick(
        category?.navigationLink,
        category?.navigationTitle,
        'main',
        category?.navigationTitle?.toLocaleLowerCase()
      )
      setIsOpenMenuModal(false)
      router.push(formatUrlSlug(category?.navigationLink))
    }
  }

  useEffect(() => {
    const handleEscKeypress = (e) => {
      if (e.key === 'Escape') {
        setSelectedCategory(null)
        menuModalHandler(true)
      }
    }

    if (typeof window !== 'undefined') {
      document.addEventListener('keydown', handleEscKeypress, { passive: true })
    }

    return () => {
      document.removeEventListener('keydown', handleEscKeypress)
    }
  }, [])

  useEffect(() => {
    let productsList = []
    if (selectedCategory && !selectedSubCategory) {
      let lists = contentBlocks?.items?.find((item) => item.sys.id === selectedCategory)
        ?.productsList?.items
      if (lists && lists.length > 0) {
        lists.map((i) => {
          productsList.push(i.productId)
        })
      }
    }
    if (selectedCategory && selectedSubCategory) {
      let mainCatSubMenu = contentBlocks?.items?.find((item) => item.sys.id === selectedCategory)
        ?.submenu?.items
      let subCategoryMenu = mainCatSubMenu.find((item) => item.sys.id === selectedSubCategory)
        ?.productsList?.items
      if (subCategoryMenu && subCategoryMenu.length > 0) {
        subCategoryMenu.map((i) => {
          productsList.push(i.productId)
        })
      }
    }
    setModalProducts(productsList)
  }, [selectedCategory, selectedSubCategory])

  const { contentBlocks, landscapeCta, topLevelLinks } = subMenu || {}
  return (
    <>
      <div className={`web-UnfixedHeader hidden lg:block top-0 z-20`}>
        {showPromobar && showPromobar}
        {/* Top menu bar */}
        <div className="container flex flex-row gap-20 items-center justify-between xl:max-w-[1230px] h-[82px]">
          {/* Logo */}
          <Link href={'/'} aria-label="SITKA">
            <Icon
              className={`transition-all hover:fill-primary fill-white cursor-pointer `}
              viewBox={`0 0 141 46`}
              icon="logoMark"
              alt="logo mark"
              height={46}
              width={141}
            />
          </Link>
          <div className={`relative flex flex-row items-center gap-6 ${hideSearch ? '' : 'grow'}`}>
            {!hideSearch && (
              <SearchBar
                modalViewHandler={modalViewHandler}
                search={search}
                searchPlaceholderText={searchPlaceholderText}
                searchRequest={searchRequest}
                setSearch={setSearch}
                customer={customer}
              />
            )}
            <Link href={'/sitka-gift-card'}>
              <a
                onClick={(e) => {
                  e.preventDefault()
                  navClick('/sitka-gift-card', 'Gift Cards', 'main', 'giftcards')
                  router.push('/sitka-gift-card')
                }}
                className='text-center xl:max-w-none max-w-[120px] break-words uppercase font-semibold xl:text-[13px] text-[12px] leading-[16.25px]
                tracking-[0.2em] h-full flex items-center cursor-pointer transition-all text-white underline hover:decoration-white decoration-2 decoration-transparent underline-offset-4'
              >
                GIFT CARDS
              </a>
            </Link>
            {hasLogin && <AccountButton customer={customer} handleClick={signInModalHandler} />}
            {hasCart && <CartButton cart={cart} cartModalViewHandler={cartModalViewHandler} />}
            {!hasLogin && (
              <div className="w-[26px] h-[26px] rounded-full animate-pulse bg-gray-200" />
            )}
            {!hasCart && (
              <div className="w-[26px] h-[26px] rounded-full animate-pulse bg-gray-200" />
            )}
          </div>
        </div>

        {/* Lower menu */}
        {!hideSubMenu && (
          <div className={`relative border-t-[#e7e5e4] border-t h-[68px] overflow-hidden`}>
            <div className={`container flex flex-row items-center xl:max-w-[1230px]`}>
              {/* Top-level menu items (nested menus) */}
              {contentBlocks && (
                <TopLevelMenus
                  contentBlocks={contentBlocks}
                  handleNavItemClick={handleNavItemClick}
                  selectedCategory={selectedCategory}
                  setPreventClickOutsideClose={setPreventClickOutsideClose}
                />
              )}
            </div>

            <div className="absolute flex items-center top-0 right-0 h-[68px] xl:gap-[16px] gap-[8px]">
              {/* Top-level menu links (single links) */}
              {topLevelLinks && <TopLevelLinks topLevelLinks={topLevelLinks} />}

              {/* Landscape CTA */}
              {landscapeCta && (
                <LandscapeCTA
                  landscapeCta={landscapeCta}
                  onLinkClick={(e, link) => {
                    e.preventDefault()
                    router.push(formatUrlSlug(link))
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <DynamicWebMenuModal
        category={contentBlocks?.items?.find((item) => item.sys.id === selectedCategory)}
        isOpenMenuModal={isOpenMenuModal}
        isValidHeight={isValidHeight}
        menuModalHandler={menuModalHandler}
        preventClickOutsideClose={preventClickOutsideClose}
        preview={preview}
        selectedSubCategory={selectedSubCategory}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        customer={customer}
        modalProducts={modalProducts}
        categoryIndex={contentBlocks?.items?.findIndex((item) => item.sys.id === selectedCategory)}
        selectedTopLevel={selectedTopLevel}
        setSelectedTopLevel={setSelectedTopLevel}
        selectedSubLevel={selectedSubLevel}
        setSelectedSubLevel={setSelectedSubLevel}
      />

      <style jsx>
        {`
          .web-UnfixedHeader {
            position: fixed;
            width: 100%;
            background-color: #111111 !important;
            // height: ${hideSubMenu ? '82px' : '150px'};
          }
        `}
      </style>
    </>
  )
}

export default WebHeader
